import { SHOWTOPNAV, HIDETOPNAV } from "./types";

const INITIAL_STATE = {
    isTopNavShown: false,
};

const navReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOWTOPNAV:
            return {
                ...state, isTopNavShown: true
            };
        case HIDETOPNAV:
            return {
                ...state, isTopNavShown: false
            };
        default: return state;
    }
};

export default navReducer;